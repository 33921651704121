<template>
  <div>
    <el-card style="min-height: 45rem">
      <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom">
        <el-form label-position="left" style="width: 26rem">
          <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">股东规则配置</div>
          <el-form-item label="业绩奖励">平台新订单
            <el-input-number v-model="form.award_scale"></el-input-number>%
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="edit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "conf",
  components:{
  },
  data() {
    return {
      form:{
        award_scale:0,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.shop.plugin.shareholder.rule().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.shop.plugin.shareholder.ruleEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>